<template>
    <div class="container">
        <div class="banner-top" v-if="BusinessData.info">
            <figure>
                <img :src="BusinessData.info.thumb" alt=""/>
            </figure>
            <div class="pbanner-title">
                <strong>{{BusinessData.info.title}}</strong>
                <div class="pbanner-next">
                    <a href="javascript:;" @click="prevPage(BusinessData.info.pre_cid)" class="prev iconfont">&#xe608;</a>
                    <a href="javascript:;" @click="prevPage(BusinessData.info.next_cid)" class="next iconfont">&#xe607;</a>
                </div>
            </div>
        </div>
        <div class="business-info" :style="{backgroundImage:`url(${BusinessBg})`}" v-if="BusinessData.info" v-html="BusinessData.info.content">
            
        </div>
        <Footer/>
    </div>
</template>

<script>
import Footer from '../../components/Footer';
export default {
    data(){
        return{
            BusinessData: [],
            BusinessBg:require('../../assets/businessbg.png'),
        }
    },
    components: {
        Footer
    },
    methods: {
         async getBusinessDetail(){
            let data = this.$route.params.cid;
            var qs = require('qs');
            const {data: res} = await this.$http.post("getBusinessInfo",qs.stringify({ 'cid': data }));
            if(res.status == 1){
                this.BusinessData = res.data;
            }
        },
        prevPage(cid){
            if(cid !== "#"){
                this.$router.push({path:`/business/businessDetail/${cid}`})
                this.getBusinessDetail();
            }
        }
    },
    mounted() {
        this.getBusinessDetail();
        
    }
}
</script>

<style lang="less">
    .banner-top{
        position: relative;
        overflow: hidden;
        height: calc(100vh - 90px);
        figure{
            display: block;
            position: relative;
            line-height: 1;
            height: 100%;
            img{
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                background: #000;
            }
        }
    }
    .pbanner-title{
        position: absolute;
        left: 10.41%;
        bottom: 96px;
        z-index: 3;
        strong{
            display: block;
            color: #ffffff;
            font-size: 48px;
            line-height: 1;
        }
    }
    .pbanner-next{
        display: flex;
        margin-top: 58px;
        a{
            display: inline-block;
            position: relative;
            z-index: 2;
            margin-right: 16px;
            width: 39px;
            height: 39px;
            border: 2px solid #fff;
            border-radius: 50%;
            text-align: center;
            line-height: 39px;
            color: #fff;
            font-size: 18px;
            overflow: hidden;
            &:before{
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                content: "";
                width: 100%;
                height: 100%;
                background: rgba(255,255,255,0.3);
                opacity: 0;
                visibility: hidden;
                transition: 0.4s;
            }
            &:hover{
                border-color: rgba(255,255,255,0.3);
                &:before{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .business-info{
        padding: 66px 10.41% 110px;
        color: #333333;
        font-size: 16px;
        line-height: 2;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        img{
            display: block;
            max-width: 100%;
            width: auto;
            height: auto;
        }
    }
</style>
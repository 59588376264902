<template>
  <div class="business-box">
      <div class="business-swiper">
            <swiper :options="swiperOption" ref="mySwiper" v-if='BusinessData.business'>
                <swiper-slide class="business-item" v-for="(item, index) in BusinessData.business" :key="index">
                    <router-link :to="'/business/businessDetail/'+item.cid" data-swiper-parallax="50%">
                        <figure><img :src="item.thumb" :alt="item.title"/></figure>
                        <div class="business-titles">
                            <strong>{{item.title}}</strong>
                            <small>{{item.en_title}}</small>
                            <span>查看详情<i></i></span>
                        </div>
                    </router-link>
                </swiper-slide>
                <swiper-slide class="business-item">
                    <Partner :partner="partner"/>
                    <Footer/>
                </swiper-slide>
            </swiper>
           <div class="swiper-pagination business-numble" slot="pagination" v-show="isShow">
                </div>
            <transition name="fadeIn1">
                <div class="business-menu" v-show="isShow">
                    <ul class="business-m-list">
                        <li v-for="(item,index) in BusinessData.business" :key="item.cid" :class="index==active?'active':''" @click="toggleSwiper(index)">{{item.title}}</li>
                    </ul>
                </div>
            </transition>
            
      </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import Partner from '../components/home/Partner';
import Footer from '../components/Footer';
export default {
    data() {
        const _self = this;
        return {
            swiperOption: {
                loop: false,
                speed:1000,
                parallax : true,
                direction : 'vertical',
                slidesPerView:'auto',
                autoplay: false,
                observeParents:false,
                cssMode:true,  
                observer:true,
                mousewheel:  {
                    releaseOnEdges: true,
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'custom',
                    renderCustom: function (swiper, current, total) {
                        return `<span>0${current}</span> / 0${total -1}`;
                    }
                },
                on:{
                    init: function(){
                        if(this.width <= 1024){
                            this.destroy(false)
                        }
                    },
                    transitionStart: function(swiper){
                        if(this.isEnd){
                            _self.isShow = false;
                        }else{
                            _self.isShow = true;
                        }
                        _self.active = this.realIndex;
                    },
                    resize: function(){
                        if(this.width <= 1024){
                            this.destroy(false)
                        }
                    }, 
                },
                
            },
            BusinessData: [],
            partner:[],
            isShow: true,
            active: 0
        }
    },
    components:{
      swiper,
      swiperSlide,
      Partner,
      Footer
    },
    methods: {
        async getBusiness(){
            const {data: res} = await this.$http.post("getBusiness");
            if(res.status == 1){
                this.BusinessData = res.data;
            }
        },
        async getHomeData(){
            const {data: res} = await this.$http.get("getIndexInfo");
            this.partner = res.data.partner;
        },
        toggleSwiper(index){
            this.active = index;
            this.$refs.mySwiper.swiper.slideTo(index, 1000, false)
        }
    },
    mounted() {
        this.getBusiness();
        this.getHomeData();
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        }
    },
}
</script>

<style lang="less">
    .business-swiper .swiper-container{ height: calc(100vh - 90px);}
    .business-item{ overflow: hidden; height: 100%;}
    .business-item a{ display: block; overflow: hidden; height: 100%;}
    .business-item:last-child{ height: auto !important;}
    .business-item a figure{ height: 100%;}
    .business-item a figure img{ display: block; width: 100%; height: 100%; object-fit: cover;}
    .business-titles{
        position: absolute;
        left: 9.375%;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        z-index: 2;
        margin-top: 10px;
        strong{
            display: block;
            margin-bottom: 14px;
            line-height: 1;
            color: #fff;
            font-size: 48px;
        }
        small{
            display: block;
            line-height: 1;
            color: #fff;
            font-size: 25px;
            font-family: "Helvetica";
        }
        span{
            display: block;
            margin-top: 52px;
            width: 150px;
            height: 42px;
            line-height: 42px;
            padding-left: 22px;
            box-sizing: border-box;
            border-radius: 21px;
            background: #ca171d;
            color: #fff;
            font-size: 16px;
            i{
                display: inline-block;
                position: relative;
                vertical-align: middle;
                margin-left: 24px;
                width: 24px;
                height: 24px;
                background: #fff;
                border-radius: 50%;
                &:before{
                    position: absolute;
                    top: 50%;
                    margin-top: -4px;
                    left: 11px;
                    content: "";
                    border-top: 4px solid transparent;
                    border-bottom: 4px solid transparent;
                    border-left: 4px solid #ca171d;
                }
            }
        }
    }
    .business-numble{
        position: absolute;
        left: 9.375% !important;
        top: 50%;
        bottom: auto !important;
        width: auto !important;
        margin-top: -100px;
        color: #fff;
        font-size: 24px;
        line-height: 1;
        z-index: 10;
        font-family: "Helvetica";
        span{
            display: inline-block;
            position: relative;
            top: 2px;
            margin-right: 7px;
            font-weight: normal;
             color: #ca171d;
            font-size: 34px;
        }
    }
    .business-menu{
        position: fixed;
        right: 0;
        top: 50%;
        margin-top: -102px;
        z-index: 10;
    }
    .business-m-list{
        li{
            position: relative;
            padding-right: 30px;
            text-align: right;
            cursor: pointer;
            margin-bottom: 20px;
            color: #fff;
            font-size: 14px;
            opacity: 0.6;
            transition: 0.4s;
            &.active,&:hover{
                color: #ca171d;
                opacity: 1;
                &:before{
                    background: #ca171d;
                    opacity: 1;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
            &:before{
                position: absolute;
                right: 0;
                top: 50%;
                content: "";
                width: 20px;
                height: 1px;
                background: #fff;
                opacity: 0.6;
                transition: 0.36s;
            }
        }
    }
    .fadeIn1-enter-active {transition: all 0.5s;}
    .fadeIn1-leave-active {transition: 0.5s;}
    .fadeIn1-enter, .fadeIn1-leave-to{opacity: 0;}
</style>